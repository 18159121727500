import React, { useEffect, useState} from 'react';
import { loginRequest } from './authConfig';
import Notifications from './components/Notifications';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import {fetchData} from "./utils/fetchData";

interface Notification {
    id: number;
    status: 'warning' | 'success' | 'error';
    subject: string;
    body: string;
    start: string;
    end: string;
    from: string;
    from_fb: string;
}

export default function App() {
    const {instance, accounts, inProgress} = useMsal();
    const [notifications, setNotifications] = useState<Notification[]>([]);

    function sendIdTokenToServer(idToken: string) {
        console.log(' in sendIdTokenToServer() ');
        fetchData(
            'https://town-crier.marvin-skynet.de/validate/jwt',
            {method: 'POST', body: {token: idToken}},
            () => fetchNotifications(),
            (error: Error) => console.error('Error sending ID token to server:', error)
        );
    }

    function fetchNotifications() {
        console.log(' in fetchNotifications() ');
        fetchData(
            'https://town-crier.marvin-skynet.de/api/v1/notifications',
            {method: 'GET'},
            (data) => {
                if (data && Array.isArray(data.notifications)) {
                    setNotifications(data.notifications);
                } else {
                    console.error('Unexpected data structure:', data);
                    setNotifications([]); // Setze einen leeren Array, um Fehler zu vermeiden
                }
            },
            (error: Error) => console.error('Error fetching notifications:', error)
        );
    }


    useEffect(() => {
        const loginRequest = {
            scopes: ["User.Read"],
            domain_hint: 'inros-lackner.de'
        };
        if (accounts.length === 0 && inProgress === "none") {
            instance.ssoSilent(loginRequest).catch((error) => {
                console.log('Silent sign-in failed', error);
                if (error.errorMessage.indexOf("user_login_error") > -1) {
                    // Fallback to login redirect if silent sign-in fails
                    instance.loginRedirect(loginRequest).catch(error => {
                        console.error('Login redirect failed', error);
                    });
                }
            });
        } else if (accounts.length > 0) {
            instance.acquireTokenSilent({
                ...loginRequest,
                account: accounts[0],
            })
                .then((response) => {
                    sendIdTokenToServer(response.idToken); // Send the accessToken to the server
                }).catch((error) => {
                console.log(error);
            });
        }
    }, [instance, accounts, inProgress,]);

    return (
        <div className="App">
            <AuthenticatedTemplate>
                <div>

                    <Notifications notifications={notifications}/>
                </div>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <h5>
                    <center>
                        Please sign-in to see your profile information.
                    </center>
                </h5>
            </UnauthenticatedTemplate>
        </div>
    );
}
