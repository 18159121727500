import React, { useEffect, useState } from 'react';
import {
    MessageBar,
    MessageBarBody,
    MessageBarTitle,
    ProgressBar,
    Dialog,
    DialogTrigger,
    DialogSurface,
    DialogTitle,
    DialogBody,
    DialogActions,
    DialogContent,
    Button
} from "@fluentui/react-components";

interface Notification {
    id: number;
    status: 'warning' | 'success' | 'error';
    subject: string | number;
    body: string;
}

interface NotificationsProps {
    notifications: Notification[];
}

const Notifications: React.FC<NotificationsProps> = ({ notifications }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const messageChangeInterval = 5; // Dauer in Sekunden für den Wechsel der Nachrichten
    const [progress, setProgress] = useState(0); // Initialer Fortschritt der ProgressBar
    const [isPaused, setIsPaused] = useState(false); // Verfolgt, ob der Countdown pausiert ist
    const [selectedNotification, setSelectedNotification] = useState<Notification | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        if (notifications.length <= 1) return; // Kein Intervall und Fortschritt, wenn nur eine oder keine Nachricht vorhanden ist

        const intervalDuration = 250; // Aktualisierungsintervall in Millisekunden
        const totalTicks = (messageChangeInterval * 1000) / intervalDuration; // Gesamtzahl der Ticks im Intervall

        const progressInterval = setInterval(() => {
            if (!isPaused) {
                setProgress((prevProgress) => {
                    const newProgress = prevProgress + (1 / totalTicks);

                    // Wenn der Fortschritt 100% erreicht, wechsel zum nächsten Index, aber nur, wenn mehr als eine Nachricht vorhanden ist
                    if (newProgress >= 1) {
                        setCurrentIndex((prevIndex) => (prevIndex + 1) % notifications.length);
                        return 0; // Setze den Fortschritt zurück, um von vorne zu beginnen
                    }
                    return newProgress;
                });
            }
        }, intervalDuration);

        return () => clearInterval(progressInterval);
    }, [currentIndex, notifications.length, isPaused]);

    const handleMouseEnter = () => setIsPaused(true);
    const handleMouseLeave = () => {
        // Setze isPaused nur auf false, wenn der Modal nicht geöffnet ist
        if (!isModalOpen) {
            setIsPaused(false);
        }
    };


    const openModal = (notification: Notification) => {
        setSelectedNotification(notification);
        setIsModalOpen(true);
        setIsPaused(true); // Pausiere die ProgressBar beim Öffnen des Modals
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedNotification(null);
        setIsPaused(false); // Pausiere die ProgressBar beim Öffnen des Modals
    };
    return (
        <>
            {notifications.length > 0 && (
                <MessageBar
                    className="messageBarPointer"
                    intent={notifications[currentIndex].status}
                    key={notifications[currentIndex].id}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onClick={() => openModal(notifications[currentIndex])}> {/* Hier fügen wir den onClick-Handler hinzu */}

                    <MessageBarBody>
                        <MessageBarTitle>
                            <h4>{notifications[currentIndex].subject.toString()}</h4>
                        </MessageBarTitle>
                        <p>{notifications[currentIndex].body}</p>
                        {/* Nachrichtenpositionsanzeige nur, wenn mehr als eine Nachricht vorhanden ist */}
                        {notifications.length > 1 && (
                            <div>
                                Nachricht {currentIndex + 1} von {notifications.length}
                            </div>
                        )}
                        {/* ProgressBar nur anzeigen, wenn mehr als eine Nachricht vorhanden ist */}
                        {notifications.length > 1 && <ProgressBar value={progress} />}
                    </MessageBarBody>
                </MessageBar>
            )}
            {isModalOpen && selectedNotification && (

                <Dialog
                    open={isModalOpen}>
                    <DialogSurface>
                        <DialogBody>
                            <DialogTitle>{selectedNotification.subject}</DialogTitle>
                            <DialogContent>
                                <p>{selectedNotification.body}</p>
                            </DialogContent>
                            <DialogActions>
                                <DialogTrigger disableButtonEnhancement>
                                    <Button onClick={closeModal} appearance="secondary">Close</Button>
                                </DialogTrigger>
                            </DialogActions>
                        </DialogBody>
                    </DialogSurface>
                </Dialog>
            )}
        </>
    );
};

export default Notifications;
