// utils/fetchData.ts
interface FetchOptions {
    method: 'GET' | 'POST';
    body?: any;
}


export const fetchData = (url: string, { method, body }: FetchOptions, onSuccess: (data: any) => void, onError: (error: Error) => void) => {
    const requestOptions: RequestInit = {
        method: method,
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
    };

    if (body && method === 'POST') {
        requestOptions.body = JSON.stringify(body);
    }

    fetch(url, requestOptions)
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
        })
        .then(onSuccess)
        .catch(onError);
};
