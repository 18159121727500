import React from 'react';
import ReactDOM from 'react-dom/client'; // Importpfad für React 18 und höher
import './index.css';
import App from './App';

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './authConfig';
import { FluentProvider, webLightTheme } from '@fluentui/react-components';

// Bootstrap components
import 'bootstrap/dist/css/bootstrap.min.css';

const msalInstance = new PublicClientApplication(msalConfig);

// Der aktuelle Ansatz zur Erstellung des Root-Elements in React 18
const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(rootElement);

/**
 * Es wird empfohlen, die meisten oder alle Komponenten in der MsalProvider-Komponente einzuschließen.
 * Am besten rendern Sie den MsalProvider so nah wie möglich an der Wurzel.
 */
root.render(
    <React.StrictMode>
        <MsalProvider instance={msalInstance}>
            <FluentProvider theme={webLightTheme}>
                <App />
            </FluentProvider>
        </MsalProvider>
    </React.StrictMode>
);
